import React from "react";
import { EmptyPlaceholder } from "../EmptyPlaceholder";
import { AdjustmentsIcon, ViewGridAddIcon } from "@heroicons/react/outline";
import LoadingAnimation from "components/ui/LoadingAnimation";
import { Button } from "components/form/Button";

const NoItemsMessage = ({
	loading,
	searchQuery,
	withEmptyPlaceholder,
	emptyPlaceholderText,
	onAddClick,
	customNoItemsMessage,
	filters = [],
	onFiltersChanged,
}) => {
	if (!!loading) {
		return (
			<div className="w-full flex flex-col items-center justify-center rounded-md py-5 bg-white">
				<LoadingAnimation />
				<span>טוען...</span>
			</div>
		);
	}

	if (!!searchQuery) {
		return `אין ממצאים לחיפוש, "${searchQuery}".`;
	}

	if (!!withEmptyPlaceholder) {
		return (
			<EmptyPlaceholder
				text={emptyPlaceholderText}
				icon={<ViewGridAddIcon className="w-6 h-6" />}
				onClick={onAddClick}
			/>
		);
	}

	if (!!customNoItemsMessage) {
		return customNoItemsMessage;
	}

	if (filters.length) {
		return (
			<div className="w-full rounded-md py-9 shadow-sm bg-white flex justify-center items-center gap-1">
				<span>אין נתונים להצגה</span>
				<Button
					primary
					className="flex items-center gap-1"
					onClick={() => onFiltersChanged([])}
				>
					<AdjustmentsIcon className="w-4 h-4" />
					נקה סינונים
				</Button>
			</div>
		);
	}

	return (
		<div className="w-full rounded-md py-9 shadow-sm bg-white flex justify-center items-center">
			<span>אין נתונים להצגה.</span>
		</div>
	);
};

export default NoItemsMessage;
